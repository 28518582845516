import React, { useEffect } from 'react'
import {Helmet}from"react-helmet";
import '../components/Styles/about2.css'
import abi from './sh5-min.png';
import AOS from 'aos';
import 'aos/dist/aos.css';


const About2 = () => {
    useEffect(()=>{
        AOS.init();
       
      })
    
  return (
    <div>
                  <Helmet>
      
      <title>Learn About Percent Fund - Our Mission, Team, and Impact - 33 Percent Fund</title>
      <meta name="description" content="Discover the mission and vision behind Percent Fund. Learn about our commitment to financial empowerment and the innovative solutions driving change."/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
      
      <section style={{width:"80%",margin:"auto"}}>
        <div className='row_bg2'>
       <div className='col2_bg'>
        <img src={abi} alt="" />
        <div className='bg__color'>
         <h2>          Embracing the Classics: A Style for Every Story
         </h2>
          </div>
       </div>
       <div className='col2_bg'>
        <img src={abi} alt="" />
        <div className='bg__color'>
         <h2>          Embracing the Classics: A Style for Every Story
         </h2>
          </div>
       </div>
       <div className='col2_bg'>
        <img src={abi} alt="" />
        <div className='bg__color'>
         <h2>          Embracing the Classics: A Style for Every Story
         </h2>
          </div>
       </div>
       <div className='col2_bg'>
        <img src={abi} alt="" />
        <div className='bg__color'>
         <h2>          Embracing the Classics: A Style for Every Story
         </h2>
          </div>
       </div>
        </div>
      </section>
    </div>
  )
}

export default About2;